@media screen and (min-width: 300px) {
    .partnerByLetter li a.inactive {
        color: rgba(26, 32, 37, .45) !important;
        cursor: default !important;
    }

    blockquote.acceptance-partner {
        font-weight: normal;
        font-style: normal;
    }

    .partnerByLetter {
        list-style-position: unset
    }

    .partnerByLetter li {
        display: inline-block;
        margin-right: 1%
    }

    .partnerByLetter li a {
        text-decoration: underline
    }

  .text-container-wrapper {

      .partner-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 10px;
        align-items: center;
        justify-content: left;
        max-width: 100%;
        text-align: center;
        flex-wrap: wrap;
      }


    .partner-list li {
      padding: 20px 30px !important;
      border: 1px solid rgba(161, 174, 183, 0.3);
      text-align: center;
    }
  }


    .partner-list li p {
        display: inline-block;
        padding-bottom: 5px;
        width: 100%;
    }

    .partner-list li img {
        display: inline-block;
        max-width: 150px;
    }

    .acceptance-partner h3 {
        border-bottom: 1px solid grey;
        padding: 5px
    }

    .acceptance-partner .partner-list {
        list-style-type: none;
        //columns: 1;
        //-webkit-columns: 1;
        //-moz-columns: 1;
        margin-top: 10px
    }

    .acceptance-partner {
        padding-top: 10px;
        padding-bottom: 10px;
        scroll-margin-top: 6em;
    }

    .text-container-wrapper ul.partnerByLetter li:before {
        content: unset !important
    }

    .text-container-wrapper ul.partner-list li:before {
        content: unset !important;
    }

    .text-container-wrapper .partnerByLetter li {
        padding-left: 15px !important
    }
}

@media screen and (min-width: 1200px) {
    .acceptance-partner .partner-list {
        list-style-type: none;
        //columns: 4;
        //-webkit-columns: 4;
        //-moz-columns: 4;
        list-style: none;
        margin-bottom: 25px;

    }
}

@media screen and (max-width: 450px) {
  .acceptance-partner {
    .partner-list {
      li {
        padding: 10px 15px !important;
        img {
          max-width: 115px;
        }
      }
    }
  }
}